import { Tag, Tooltip } from 'antd';
import clsx from 'clsx';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Icon, Label } from 'semantic-ui-react';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  MailOutlined,
} from '@ant-design/icons';

import {
  statusColors,
  USER_ROLES,
  approverStatuses,
  auditorStatuses,
} from '@constants';
import { useGetRoles } from '@hooks/useGetRoles';
import DropdownMenu from '@components/DropdownMenu';
import NewModal from '@components/Modal/NewModal';
import TagsEditor from '@components/TagsEditor';
import usePermissions from '@hooks/usePermissions';
import { dayjsWithTimezone } from '@services/helpers';

import s from './styles.module.scss';

const showApproveButton = (roles, status) => {
  const userIsAprover = roles.includes(USER_ROLES.approver);
  const userIsAuditor = roles.includes(USER_ROLES.auditor);

  if (userIsAprover && approverStatuses.includes(status)) return true;

  return !!(userIsAuditor && auditorStatuses.includes(status));
};

const Header = ({
  workItem,
  clientName,
  clientEmail,
  onEmailsClick,
  onSaveEmail,
  isSaveActive,
  lockedBy,
  readOnly,
  onUnlock,
  onTagsChange,
  onRemove,
  availableTags,
  discarded,
  onWorkItemChange,
  hasCloseIcon,
  actionsDisabled,
  onFetchWorkItem,
}) => {
  const navigate = useNavigate();
  const date = dayjsWithTimezone(workItem.receivedAt).format(
    'MM/DD/YYYY, HH:mm',
  );
  const emailDate = dayjsWithTimezone(workItem.emailedAt).format(
    'MM/DD/YYYY, HH:mm',
  );
  const batchId = workItem?.batch?.data?.id;
  const batchSubmissionId =
    workItem?.batch?.data?.attributes?.submissionId || '';
  const workItemCurrentStatus = workItem?.status?.name;
  const hyperscienceId = workItem?.externalId;
  const outboundBatchId = workItem?.outputBatchId;
  const color = statusColors.get(workItemCurrentStatus);
  const userRoles = useGetRoles();
  const showApprove = showApproveButton(userRoles, workItem?.status?.name);
  const [modal, setModal] = useState({
    type: '',
    message: '',
  });
  const { canDeleteWorkItems } = usePermissions();

  const workItems = workItem?.batch?.data?.attributes?.workItems || [];
  const workItemId = parseInt(workItem.id, 10);
  const currentIndex = workItems.findIndex(
    (currentWorkItem) => parseInt(currentWorkItem, 10) === workItemId,
  );
  const currentWICount = currentIndex >= 0 ? currentIndex + 1 : null;

  const onUnlockClick = () => {
    setModal({
      type: 'unlock',
      message: 'Are you sure you want to unlock this work item?',
    });
  };

  const onRemoveClick = () => {
    if (!discarded?.length) {
      setModal({
        type: 'remove',
        message: 'Are you sure you want to delete this work item?',
      });
    } else {
      setModal({
        type: 'restore',
        message: 'Are you sure you want to restore this work item?',
      });
    }
  };

  const modalActions = {
    unlock: () => {
      onUnlock();
      setModal({
        type: '',
        message: '',
      });
    },
    remove: () => {
      onRemove();
      setModal({
        type: '',
        message: '',
      });
    },
    restore: () => {
      onRemove();
      setModal({
        type: '',
        message: '',
      });
    },
    cancel: () => {
      setModal({
        type: '',
        message: '',
      });
    },
  };

  return (
    <>
      {modal?.type && (
        <NewModal
          title="Confirm"
          onCancel={() => modalActions.cancel()}
          onCrossClick={() => modalActions.cancel()}
          onSubmit={() => modalActions[modal?.type]()}
          submitButtonText="Yes"
          cancelButtonText="Cancel"
          visible
        >
          {modal?.message}
        </NewModal>
      )}
      <div className={s.approval}>
        <div className={s.headerButtons}>
          <DropdownMenu
            disabled={lockedBy || actionsDisabled}
            onFetchWorkItem={onFetchWorkItem}
          />
          <Tooltip
            title={
              !workItem?.outputFields?.client?.id &&
              'Please choose a client in order to group this work item'
            }
          >
            <Button
              type="button"
              className={s.groupingButton}
              onClick={() => navigate(`/grouping/${workItem.id}`)}
              disabled={!workItem?.outputFields?.client?.id || readOnly}
            >
              Grouping
            </Button>
          </Tooltip>
          {canDeleteWorkItems && (
            <Button
              type="button"
              className={s.groupingButton}
              onClick={onRemoveClick}
              disabled={lockedBy}
            >
              {discarded ? 'Restore' : 'Delete'} work item
            </Button>
          )}
          <Button
            type="button"
            className={s.emailsButton}
            onClick={onEmailsClick}
            disabled={readOnly}
          >
            Email client &nbsp;
            {workItem?.status?.name === 'replied' && (
              <Label circular color="red" className={s.emailBudge} />
            )}
            <MailOutlined />
          </Button>
          {lockedBy?.user?.name && (
            <div className={s.lockedBlock}>
              <div className={s.userName}>
                Locked by: {lockedBy?.user?.name}
              </div>
              <div className={s.unlockButton} onClick={onUnlockClick}>
                Unlock
              </div>
            </div>
          )}
          <div
            className={clsx(s.download, {
              [s.downloadButtonMargin]: !showApprove,
            })}
          />
        </div>
        <div className={s.headerBatchInfo}>
          <div className={s.batchId}>
            Batch #: {batchId} ({batchSubmissionId})
          </div>
          <div className={s.workItemId}>
            Work Item: {workItem.id} ({hyperscienceId})
          </div>
          <div className={s.workItemId}>
            Work Item: {currentWICount}/
            {workItem?.batch?.data?.attributes?.workItems?.length}
          </div>
          <div className={s.workItemId}>
            Outbound Batch ID: {outboundBatchId}
          </div>
        </div>
        <div className={s.navigationBar}>
          {onWorkItemChange && (
            <Tooltip title="Previous Work Item">
              <ArrowLeftOutlined
                style={{
                  fontSize: 15,
                  marginRight: 10,
                }}
                onClick={() => onWorkItemChange('prev')}
              />
            </Tooltip>
          )}
          {onWorkItemChange && (
            <Tooltip title="Next Work Item">
              <ArrowRightOutlined
                style={{
                  fontSize: 15,
                  marginRight: 10,
                }}
                onClick={() => onWorkItemChange('next')}
              />
            </Tooltip>
          )}
          {hasCloseIcon && (
            <Link style={{ textDecoration: 'none', color: 'unset' }} to="/">
              <Icon name="close" />
            </Link>
          )}
        </div>
      </div>

      <div className={s.invoice}>
        <div className="email">
          <div className={s.workItemName}>{clientName}</div>
          <div className={s.workItemEmail}>
            {clientEmail}
            <div
              className={clsx(
                s.saveEmailButton,
                isSaveActive && s.saveEmailButtonActive,
              )}
              onClick={() => {
                onSaveEmail(clientEmail);
              }}
            >
              Save email address
            </div>
          </div>
        </div>
        <div className="date">
          Email date:
          <div className={s.invoiceData}>{emailDate}</div>
        </div>
        <div className="date">
          Received at:
          <div className={s.invoiceData}>{date}</div>
          <div className={s.invoiceStatusText}>
            {workItem?.batch?.data?.attributes?.subject_line}
          </div>
        </div>
        <div>
          <Tag style={{ width: '100%', textAlign: 'center' }} color={color}>
            {workItem?.status?.caption}
          </Tag>
        </div>
        <TagsEditor
          value={workItem?.tags?.map((tag) => tag?.attributes?.name) || []}
          onChange={onTagsChange}
          availableTags={availableTags}
          readOnly={readOnly}
        />
      </div>
    </>
  );
};

export default Header;
